import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import FirmantesModal from './FirmantesModal';
import Viewer from './Viewer';

const PdfModal = ({ file, open, handleClose, validationResult }) => {
  const [firmantesOpen, setFirmantesOpen] = useState(false);

  const handleFirmantesOpen = () => {
    setFirmantesOpen(true);
  };

  const handleFirmantesClose = () => {
    setFirmantesOpen(false);
  };

  const getValidationMessage = () => {
    if (!validationResult) return '';

    const { resultsDigitales, fechaInicio, fechaFin } = validationResult;
    const { estadoRevocacion } = resultsDigitales[0];
    const fechaInicioFormatted = new Date(fechaInicio).toLocaleDateString();
    const fechaFinFormatted = fechaFin ? new Date(fechaFin).toLocaleDateString() : '-';

    if (estadoRevocacion === 'OK') {
      return (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <CheckCircleIcon sx={{ color: 'green', marginRight: 1 }} />
            <Typography variant="body1">Todas las firmas son VÁLIDAS</Typography>
          </Box>
          <Typography variant="body2">No ha habido modificaciones en el documento desde que se firmó</Typography>
          <Typography variant="body2">El documento se ha validado en la plataforma de Firma Ciudadana</Typography>
          <Typography variant="body2">Los firmantes han sido correctamente identificados</Typography>
          <Typography variant="body2">El circuito de firmas inició el: {fechaInicioFormatted} y finalizó el {fechaFinFormatted}</Typography>
        </>
      );
    } else if (estadoRevocacion === 'REVOCADO') {
      return (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <CancelIcon sx={{ color: 'red', marginRight: 1 }} />
            <Typography variant="body1">Hay firmas INVÁLIDAS</Typography>
          </Box>
          <Typography variant="body2">Se realizaron modificaciones en el documento desde que se firmó</Typography>
          <Typography variant="body2">El documento NO se pudo validar en la plataforma de Firma Ciudadana</Typography>
          <Typography variant="body2">Los firmantes NO se pudieron identificar</Typography>
          <Typography variant="body2">El circuito de firmas inició el: {fechaInicioFormatted} y finalizó el {fechaFinFormatted}</Typography>
        </>
      );
    } else if (estadoRevocacion === 'NO_SE_PUEDE_COMPROBAR') {
      return (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <WarningIcon sx={{ color: 'orange', marginRight: 1 }} />
            <Typography variant="body1">Hay aspectos de la firmas que no pudieron validarse</Typography>
          </Box>
          <Typography variant="body2">No ha habido modificaciones en el documento desde que se firmó</Typography>
          <Typography variant="body2">El documento se ha validado en la plataforma de Firma Ciudadana</Typography>
          <Typography variant="body2">Los firmantes han sido correctamente identificados</Typography>
          <Typography variant="body2">El circuito de firmas inició el: {fechaInicioFormatted} y finalizó el {fechaFinFormatted}</Typography>
        </>
      );
    }
  };

  const resultsElectronicas = validationResult ? validationResult.resultsElectronicas : [];
  const resultsDigitales = validationResult ? validationResult.resultsDigitales : [];
  console.log("file modal", file)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="pdf-modal-title"
      aria-describedby="pdf-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "90%",
          borderRadius: "0",
          bgcolor: 'background.paper',
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >

          <Box
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            <Viewer fileUrl={file} />
          </Box>
          <Box
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              p: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Estado de validación de firmas
            </Typography>
            {getValidationMessage()}
            <Button variant="contained" onClick={handleFirmantesOpen} sx={{
              bgcolor: "#356396", mt: 2, '&:hover': {
                bgcolor: "#356396",
              },
            }}>
              Ver Panel de Firmas
            </Button>
          </Box>
        </Box>
        <FirmantesModal
          open={firmantesOpen}
          handleClose={handleFirmantesClose}
          firmasElectronicas={resultsElectronicas}
          firmasDigitales={resultsDigitales}
        />
      </Box>
    </Modal>
  );
};

export default PdfModal;
