import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';

const FirmaAvanzadaModal = ({ open, handleClose, firma }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="firma-avanzada-modal-title"
            aria-describedby="firma-avanzada-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography id="firma-avanzada-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Propiedades Avanzadas {firma.valida ? null : <CancelIcon sx={{ color: 'red', ml: 1 }} />}
                </Typography>
                <Typography>Firmado por {firma.nombreComun} - CUIT: {firma.cuit}</Typography>
                <Typography>Algoritmo de Hash: {firma.mecanismo}</Typography>
                <Typography>Algoritmo de Firma: RSA</Typography>
                <Typography>Firma habilitada para LTV (Long Time Validation): {firma.esLongeva ? 'Sí' : 'No'}</Typography>
                <Button variant="contained" color="primary" sx={{
                    bgcolor: "#356396", mt: 2, '&:hover': {
                        bgcolor: "#356396",
                    }, mr: 2
                }}>
                    Ver certificado del firmante
                </Button>
            </Box>
        </Modal>
    );
};

export default FirmaAvanzadaModal;
